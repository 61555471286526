<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="$t('pageTitle.Mission')"
  >
    <div class="campaign-content">
      <div class="image-container">
        <img
          v-if="campaignData && campaignData.url_mission_cover"
          :src="campaignData.url_mission_cover"
          @error="handleImgError"
          alt="Mission Cover"
          class="mission-cover"
        />
      </div>

      <div
        v-if="campaignData && campaignData.mission_description"
        class="mission-description"
      >
        <div
          v-html="$root.translate(campaignData.mission_description)"
          class="mission-text"
        ></div>
      </div>

      <div
        v-if="loadinGetUserAvatar && campaignData.avatar_personalizzati"
        class="custom-avatar-section flex justify-content-center align-items-center"
      >
        <ion-spinner name="crescent"></ion-spinner>
      </div>

      <div
        v-else-if="campaignData.avatar_personalizzati && avatarList"
        id="custom-avatar-selection"
        class="custom-avatar-section"
      >
        <h4 v-if="!userAvatar.id" class="font-bold">
          Seleziona un avatar personalizzato per la campagna
        </h4>
        <h4 v-else>Il tuo Avatar per questa campagna</h4>
        <avatars-slider
          v-if="!userAvatar.id"
          @changeCurrentSlide="getCurrentAvatar"
          @clickedCurrentSlide="selectCurrentAvatar"
          :awardsList="userAvatar.id ? [userAvatar] : avatarList"
          :showUserProfile="true"
        ></avatars-slider>

        <div class="user-with-avatar" v-if="userAvatar.id">
          <img class="user-with-avatar-avatar" :src="userAvatar.url" alt="" />
          <img
            class="user-with-avatar-user"
            :src="currentUser.avatar_url"
            alt=""
          />
        </div>

        <div class="avatar-details" v-if="currentAvatar">
          <h4>{{ $root.translate(currentAvatar.t_nome) }}</h4>
          <p>{{ $root.translate(currentAvatar.t_descrizione) }}</p>

          <div class="avatar-details-badges">
            <ion-card
              class="avatars-badge-row mb-2 p-d ion-no-margin"
              v-for="badge in currentAvatar.Badges"
              :key="badge.id"
            >
              <img
                :src="badge.url_image"
                style="height: 30px; width: 30px; border-radius: 50%"
              />
              <div class="w-full flex flex-column justify-content-start ml-2">
                <span class="ml-2">{{ badge.title["it-IT"] }}</span>
                <common-progress-bar
                  class="w-100"
                  :value="badge.percentuale / 100"
                ></common-progress-bar>
              </div>
            </ion-card>
          </div>
        </div>

        <!-- <h4>Avatar selezionato</h4> -->
        <!-- <div class="selected-avatars"> -->
        <div class="w-full flex justify-content-center align-items-center my-4">
          <div v-if="selectedAvatars.length <= 0">
            Non hai selezionato nessun Avatar
          </div>

          <button
            v-else-if="!userAvatar.id"
            @click="confermaAvatar"
            class="button-primary p-d"
            :disabled="selectedAvatars.length <= 0 || loadingConferma"
            expand="block"
          >
            {{ loadingConferma ? "Conferma in corso..." : "Conferma" }}
          </button>
        </div>
      </div>

      <div
        v-if="campaignData.assegnazione_badge"
        id="custom-badge-selector"
        class="custom-badge-section"
      >
        <h4 v-if="userBadges.length == 0" class="font-bold">
          Seleziona i tuoi
          <span class="font-bold">{{ campaignData.nMaxDoppiBadgeUser }}</span>
          Badge prioritari!
        </h4>
        <h4 v-if="userBadges.length > 0">I tuoi Badge</h4>
        <avatars-slider
          v-if="userBadges.length == 0"
          @changeCurrentSlide="getCurrentBadge"
          @clickedCurrentSlide="selectCurrentBadge"
          :awardsList="badgesList"
        ></avatars-slider>

        <div
          v-if="currentBadge && userBadges.length == 0"
          class="badge-details"
        >
          <p>
            <strong>{{ $root.translate(currentBadge.title) }}</strong>
          </p>

          <p>
            {{ $root.translate(currentBadge.description) }}
          </p>
        </div>
        <h4 v-if="userBadges.length == 0">Badge selezionati</h4>
        <div
          class="flex flex-column align-items-center"
          :class="userBadges.length == 0 ? 'selected-badges' : 'user-badges'"
        >
          <div v-if="selectedBadges.length <= 0">
            Non hai selezionato nessun Badge
          </div>
          <div
            v-else
            class="w-full flex justify-content-center align-items-center"
          >
            <div
              class="selected-badges-icons mx-2"
              v-for="badge in selectedBadges"
              :key="badge.id"
            >
              <img
                @click="
                  userBadges.length == 0
                    ? removeBadge(badge.id)
                    : showBadgeDetails(badge)
                "
                :src="badge.url_image"
                @error="handleImgError"
                alt="Badge"
                class="badge-icon"
              />
            </div>
          </div>

          <div
            class="w-full flex flex-column justify-content-center align-items-center my-4"
            style="min-height: 130px"
            v-if="userBadges.length > 0 && currentBadgeDetails.id"
          >
            <span class="my-2 font-bold">
              {{ $root.translate(currentBadgeDetails.title) }}
            </span>
            <span>
              {{ $root.translate(currentBadgeDetails.description) }}
            </span>
          </div>

          <button
            v-if="userBadges.length == 0"
            @click="confermaBadge"
            class="button-primary p-d mt-4"
            :disabled="selectedBadges.length <= 0 || loadingConfermaBadge"
            expand="block"
          >
            {{ loadingConfermaBadge ? "Conferma in corso..." : "Conferma" }}
          </button>
        </div>
      </div>

      <div class="action-button-container">
        <button @click="close" class="action-button">
          {{ $t("mission.close") }}
        </button>
      </div>
    </div>
  </campaign-layout>
</template>

<script>
import { IonButton, IonCard, IonSpinner } from "@ionic/vue";
import ApiService from "../../common/service.api";
import CampaignLayout from "../../components/CampaignLayout";
import AvatarsSlider from "../../components/AvatarsSlider.vue";
import CampaignService from "../../common/mixins/Campaign";
import CommonProgressBar from "@/components/CommonProgressBar.vue";

export default {
  name: "Mission",
  components: {
    CampaignLayout,
    AvatarsSlider,
    IonButton,
    IonCard,
    IonSpinner,

    CommonProgressBar,
  },
  mixins: [CampaignService],
  data() {
    return {
      campaignData: {},
      selectedBadges: [],
      selectedAvatars: [],
      currentBadgeIndex: 0,
      currentAvatarIndex: 0,
      badgesList: [],
      avatarList: [],

      userAvatar: {},
      userBadges: [],

      loadingConferma: false,
      loadingConfermaBadge: false,
      loadinGetUserAvatar: false,
      loadingGetUserPriorityBadge: false,

      currentBadgeDetails: {},
    };
  },
  computed: {
    currentAvatar() {
      return this.avatarList[this.currentAvatarIndex];
    },
    currentBadge() {
      return this.badgesList[this.currentBadgeIndex];
    },
    computed_debug() {
      return this.$cookies.get("debug") !== 1;
    },
    currentUser() {
      return this.$store.getters["user/getUserData"];
    },
  },
  methods: {
    showBadgeDetails(badge) {
      this.currentBadgeDetails = badge;
    },
    async getUserAvatar() {
      this.loadinGetUserAvatar = true;
      try {
        const res = await ApiService.get(
          "CampaignManager/GetUserAvatar/" + this.campaignId
        );

        console.log("GetUserAvatar", res);

        if (res.data.id) {
          this.currentAvatar = {
            url: res.data.url || "https://placehold.co/300x300?text=Avatar",
            ...res.data,
          };
          this.selectedAvatars.splice(0);
          this.selectedAvatars.push(this.currentAvatar);

          this.userAvatar = this.currentAvatar;
        } else {
          this.userAvatar = {};
          this.selectedAvatars.splice(0);
        }
      } catch (error) {
        console.log(error);
      }
      this.loadinGetUserAvatar = false;
    },
    async getUserPriorityBadges() {
      this.loadingGetUserPriorityBadge = true;
      this.selectedBadges.splice(0);
      this.userBadges.splice(0);

      try {
        const res = await ApiService.get(
          "CampaignManager/GetUserPriorityBadge/" + this.campaignId
        );
        for (let b of res.data) {
          this.selectedBadges.push(this.getBadgeFromId(b));
        }
        this.userBadges = res.data;
      } catch (error) {
        console.log(error);
      }
      this.loadingGetUserPriorityBadge = false;
    },
    getBadgeFromId(id) {
      return this.badgesList.find((b) => b.id === id);
    },
    async confermaAvatar() {
      this.loadingConferma = true;
      try {
        const res = await ApiService.post(
          "CampaignManager/SetUserAvatar/" + this.selectedAvatars[0].id
        );
      } catch (error) {
        console.log(error);
      }
      this.loadingConferma = false;
    },
    async confermaBadge() {
      this.loadingConfermaBadge = true;

      try {
        for (let b of this.selectedBadges) {
          await ApiService.post(
            "CampaignManager/SetUserPriorityBadge/" +
              this.campaignId +
              "/" +
              b.id
          );
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingConfermaBadge = false;
    },
    async getAvatarList() {
      this.avatarList.splice(0);
      try {
        const res = await ApiService.get(
          "CampaignManager/GetAvatars/" + this.campaignId
        );

        if (res.data) {
          this.avatarList = res.data.map((avatar) => {
            return {
              ...avatar,
              url_image:
                avatar.url || "https://placehold.co/300x300?text=Avatar",
            };
          });
        } else {
          this.avatarList = [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.currentAvatarIndex = 0;
        this.currentAvatar = this.avatarList[this.currentAvatarIndex];
        this.selectedAvatars.splice(0);
        this.selectedAvatars.push(this.currentAvatar);
      }
    },
    handleImgError(event) {
      event.target.src = "https://placehold.co/300x300?text=MartechRevolution";
    },
    getCurrentAvatar(index) {
      this.currentAvatarIndex = index;
      this.selectedAvatars.splice(0);
      this.selectedAvatars.push(this.currentAvatar);
    },
    getCurrentBadge(index) {
      this.currentBadgeIndex = index;
    },
    selectCurrentAvatar(id) {
      this.selectedAvatars.splice(0);

      id = id || this.currentAvatar.id;

      if (this.selectedAvatars.length < 1) {
        this.selectedAvatars.push(this.currentAvatar);
      }
    },
    selectCurrentBadge(id) {
      id = id || this.currentBadge.id;

      console.log("MAX BADGES : ", this.campaignData.nMaxDoppiBadgeUser);

      if (this.selectedBadges.length < this.campaignData.nMaxDoppiBadgeUser) {
        this.selectedBadges.push(this.currentBadge);
      }
    },
    removeAvatar(id) {
      const index = this.selectedAvatars.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.selectedAvatars.splice(index, 1);
      }
    },
    removeBadge(id) {
      const index = this.selectedBadges.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.selectedBadges.splice(index, 1);
      }
    },
    close() {
      this.$router.push(this.getCampaignDefaultUrl(this.campaignData, true));
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    this.campaignId = id;
    await this.getCampaign(id).then((data) => {
      this.campaignData = data;
      ApiService.get(`Badge/GetPriority?idCampagna=${id}`).then((res) => {
        this.badgesList = res.data;
      });
    });
    if (this.campaignData.avatar_personalizzati) {
      await this.getAvatarList();
      await this.getUserAvatar();
      await this.getUserPriorityBadges();
    }
  },
};
</script>

<style scoped lang="scss">
.campaign-content {
  padding: 16px;
}

.image-container {
  text-align: center;
  margin-bottom: 16px;
}

.mission-cover {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mission-description {
  margin-bottom: 24px;
}

.mission-text {
  white-space: pre-wrap;
  text-align: justify;
}

.custom-avatar-section,
.custom-badge-section {
  margin-bottom: 32px;
  border: 2px solid var(--ion-color-primary);
  padding: 15px;
  border-radius: var(--ion-border-radius);
  background-color: #f9f9f9;
}

.custom-avatar-section h4,
.custom-badge-section h4 {
  text-align: center;
  margin-bottom: 16px;
  font-size: 1.25rem;
  color: var(--ion-color-primary);
}

.avatar-details,
.badge-details {
  margin-top: 16px;
  padding: 20px;
  background-color: #fff;
  border-radius: var(--ion-border-radius);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: 200px;
}

.avatar-details-badges {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.selected-avatars,
.selected-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: var(--ion-border-radius);
}

.user-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  padding: 15px;
  border-radius: var(--ion-border-radius);
}

.selected-avatars-icons,
.selected-badges-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatars-badge-row {
  max-height: 70px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar-icon,
.badge-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.avatar-icon:hover,
.badge-icon:hover {
  transform: scale(1.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.action-button-container {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.action-button {
  padding: 12px 24px;
  background: var(--ion-color-primary);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.action-button:hover {
  background: var(--ion-color-primary);
}

.user-with-avatar {
  position: relative;
  width: 100%;
  height: 200px;
}

.user-with-avatar-avatar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
}

.user-with-avatar-user {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;

  border-radius: 50%;
  z-index: 1;
}
</style>
